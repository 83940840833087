import { useHistory } from "react-router-dom";

import firebaseAuth from "../config/firebase";
import { useAppContext } from "../utils/contextLib";

const useLogoutFirebase = (): [() => void] => {
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  const logOutFromFirebase = async () => {
    await firebaseAuth.signOut().then(() => {
      localStorage.removeItem("3wo-token");
      userHasAuthenticated(false);
      history.push("/login");
    });
  };

  return [logOutFromFirebase];
};

export default useLogoutFirebase;
