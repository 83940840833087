import React from "react";

import { FiShoppingCart } from "react-icons/fi";
import { HiOutlineHeart } from "react-icons/hi";
import { RiMapPin2Line } from "react-icons/ri";

const MemberVisualSummary = function (props: {
  countOfEcomPayments: number;
  countOfPOSPayments: number;
  totalAmountOfPoints: number;
}) {
  const { countOfEcomPayments, countOfPOSPayments, totalAmountOfPoints } =
    props;

  return (
    <div className="grid grid-cols-3 mt-4 mb-1 lg:my-5 h-20 text-white font-thin">
      <div className="items-center flex flex-col -mr-1">
        <RiMapPin2Line size={25} />
        <div className="bg-green-600 mt-2 h-6 w-full rounded-l-full text-sm font-bold ">
          {countOfPOSPayments}
        </div>
        <p>in-store</p>
      </div>
      <div className="items-center flex flex-col">
        <FiShoppingCart size={25} />
        <div className="bg-green-600 mt-2 h-6 w-full text-sm font-bold">
          {countOfEcomPayments}
        </div>
        <p>online</p>
      </div>

      <div className="items-center flex flex-col">
        <HiOutlineHeart size={25} />
        <div className="bg-green-600 mt-2 h-6 w-full rounded-r-full text-sm font-bold ">
          {totalAmountOfPoints}
        </div>
        <p>punten</p>
      </div>
    </div>
  );
};

export default MemberVisualSummary;
