import React from "react";

const NumberPadKey = function (props: {
  children: string;
  onClick: () => void;
}) {
  const { children, onClick } = props;

  return (
    <button
      className="rounded-full bg-white w-20 h-20 mx-0 justify-self-center text-xl font-bold focus:outline-none"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default NumberPadKey;
