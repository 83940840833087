/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";

import ClientInformation from "../components/ClientInformation/ClientInformation";
import ManualPointsButton from "../components/ManualPointsButton/ManualPointsButton";
import Navbar from "../components/Navbar/Navbar";
import RedeemButton from "../components/RedeemButton/RedeemButton";
import SearchBox from "../components/SearchBox/SearchBox";
import {
  AdyenStatusNotification,
  useAdyenStatusNotificationSubscription,
  useCardAcquisitionMutation,
  useGetDataFromSearchedUserLazyQuery,
} from "../graphql/__generated__/graphql_types";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
};

const HomeOverviewPage = function () {
  const [userData, setUserdata] = useState<AdyenStatusNotification>();
  const [userFromSearch, setUserFromSearch] = useState(initialState);
  // TODO: This is now hardcoded to their terminal from ADyen at their office.
  const [selectedTerminal, setSelectedTerminal] = useState<string>("");

  const searchRef = useRef<any>(null);

  // TODO: Terminal is now hardcoded. Create feature to select one.
  const { data: { adyenIncomingPayment } = {} } =
    useAdyenStatusNotificationSubscription({
      variables: {
        terminalId: selectedTerminal,
      },
    });

  const [getDataViaCardAcquisition] = useCardAcquisitionMutation({
    variables: {
      terminalId: selectedTerminal,
    },
  });

  const cardAcquisition = () => {
    getDataViaCardAcquisition()
      .then(({ data }) => {
        const user =
          data?.cardAcquisition.__typename === "User" &&
          data.cardAcquisition.email
            ? data.cardAcquisition
            : null;

        if (user) {
          setUserFromSearch({ ...user });
          toast.success("User gevonden");
          return;
        }

        if (data?.cardAcquisition.__typename === "SMSPayload") {
          toast.success("Registratie sms is verzonden");
          setUserdata(undefined);
        }
      })
      .catch(() => {
        toast.error("Er ging iets mis...");
        setUserdata(undefined);
      });
  };

  const [getData] = useGetDataFromSearchedUserLazyQuery({
    onCompleted: ({ getDataFromSearchedUser }) => {
      const {
        countOfEcomPayments,
        countOfPOSPayments,
        totalCountOfTransactions,
        totalAmountOfPoints,
        user,
      } = getDataFromSearchedUser;
      setUserdata({
        countOfEcomPayments,
        countOfPOSPayments,
        totalCountOfTransactions,
        totalAmountOfPoints,
        user,
        isCardRecognized: true,
        status: "",
      });
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (adyenIncomingPayment?.status === "pending") {
      setUserdata({
        status: "pending",
        ...{},
      });
      setUserFromSearch(initialState);
      // Clear search input field when payment is coming in.
      searchRef.current.clearState();
    }
  }, [adyenIncomingPayment?.status]);

  useEffect(() => {
    // use effect to fill a userData object to pass as props, when user is recognized
    if (
      adyenIncomingPayment &&
      adyenIncomingPayment.status !== "pending" &&
      adyenIncomingPayment.user
    ) {
      const {
        countOfEcomPayments,
        countOfPOSPayments,
        totalAmountOfPoints,
        amountOfLastPayment,
        user,
        status,
        totalCountOfTransactions,
        date,
      } = adyenIncomingPayment;

      setUserdata({
        amountOfLastPayment: amountOfLastPayment!,
        countOfEcomPayments: countOfEcomPayments!,
        countOfPOSPayments: countOfPOSPayments!,
        totalAmountOfPoints: totalAmountOfPoints!,
        user,
        totalCountOfTransactions,
        status,
        date,
      });
    }
  }, [adyenIncomingPayment]);

  useEffect(() => {
    if (adyenIncomingPayment?.status !== "pending")
      setUserdata(adyenIncomingPayment);
  }, [adyenIncomingPayment]);

  useEffect(() => {
    const getUserData = async () => {
      if (userFromSearch?.email.length) {
        // TODO: Fill userData and create userData object
        getData({
          variables: {
            email: userFromSearch.email,
          },
        });
      }
    };
    getUserData();
  }, [getData, userFromSearch.email]);

  const selectTerminal = (terminal: string) => {
    setSelectedTerminal(terminal);
  };

  // TODO: Check if process can be more optimized. To put state level up for example
  return (
    <div className="h-screen w-screen flex-col items-center justify-center">
      <div className="fixed bottom-0 left-0 right-0 h-screen top-0 bg-gray-interface bg-cover bg-no-repeat bg-center -z-1" />
      <Navbar
        backIcon={false}
        selectedTerminal={selectedTerminal}
        selectTerminal={selectTerminal}
      />
      <div className="container mx-auto lg:max-w-4xl 2xl:max-w-7xl grid grid-cols-2 lg:gap-32 max-w-3xl gap-20 mt-14 lg:mt-24">
        <ClientInformation memberInformation={userData} />
        <div className="flex flex-col lg:h-full justify-between">
          <SearchBox
            orderId={adyenIncomingPayment?.orderId}
            cardAlias={adyenIncomingPayment?.customerKey}
            setUserFromSearch={setUserFromSearch}
            ref={searchRef}
            isCardAlreadyConnected={!!userData?.isCardConnectedToUser}
          />
          <div className="grid grid-cols-2 h-64 lg:mt-0 -mt-28">
            <button
              type="button"
              className="bg-blue-loyyo-dark-color text-white col-span-2 rounded-2xl w-full h-20 px-2 text-center justify-center"
              onClick={cardAcquisition}
            >
              <div className="flex justify-center w-full items-center">
                <p className="text-xl font-semibold">Klantherkenning via PIN</p>
              </div>
            </button>

            <div className="flex w-full col-span-2">
              <ManualPointsButton
                enabled={!!adyenIncomingPayment?.user || !!userFromSearch.email}
                customerData={adyenIncomingPayment?.user || userFromSearch}
                totalAmountOfPoints={userData?.totalAmountOfPoints as number}
              />
              <RedeemButton
                enabled={!!adyenIncomingPayment?.user || !!userFromSearch.email}
                customerData={adyenIncomingPayment?.user || userFromSearch}
                totalAmountOfPoints={userData?.totalAmountOfPoints as number}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOverviewPage;
