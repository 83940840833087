import React, { useState } from "react";

import { FaRegUserCircle } from "react-icons/fa";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const AddPointsInfo = function ({
  customerData,
  price,
}: {
  customerData: {
    email: string;
    firstName: string;
    lastName: string;
    totalAmountOfPoints: number;
  };
  price: string;
}) {
  const [isCorrection, setIsCorrection] = useState<boolean>(false);

  const points = Math.floor(parseFloat(price) / 100) || 0;

  const handleChangeToggle = () => {
    setIsCorrection(!isCorrection);
  };

  return (
    <>
      <div className="bg-green-success h-16 lg:h-20 rounded-t-xl flex flex-col items-center">
        <div className="bg-green-success h-16 lg:h-20 w-auto rounded-full -mt-6 lg:-mt-8 pt-2 px-2 flex justify-center">
          <FaRegUserCircle size={30} color="white" />
        </div>
        <div className="text-white h-full">
          <h1>Handmatig punten geven</h1>
        </div>
      </div>
      <div className="lg:h-96 h-3/4 px-6 py-2 flex flex-col justify-around items-center">
        <div className="self-center w-full text-center">
          <div className="justify-center flex items-center">
            <h2>{`${customerData.firstName} ${customerData.lastName}`}</h2>
            {/* <BsArrowRight size={30} /> */}
          </div>
          <div className="items-center flex flex-col">
            {/* <HiOutlineHeart size={20} /> */}
            <div className="bg-green-300 mt-2 h-5 w-2/3 rounded-full text-center text-sm font-bold text-black">
              {customerData.totalAmountOfPoints} + {points}
            </div>
            <p className="text-gray-600">punten</p>
          </div>

          <p className="text-gray-500 italic my-2">
            Vul rechts het bedrag in van de bestelling. De punten worden
            automatisch berekend.
          </p>
          {/* <p className="mt-1 text-sm"> Bijvoorbeeld: 25.00 is 25 punten</p> */}
        </div>

        <p className="text-gray-500 italic text-center">
          Selecteer hieronder &quot;support / promotie&quot;, wanneer het niet
          om een transactie gaat.
        </p>
        <div className="flex text-center">
          <Toggle defaultChecked={false} onChange={handleChangeToggle} />

          <span className="text-gray-500 italic ml-2">Support / Promotie</span>
        </div>
      </div>
    </>
  );
};

export default AddPointsInfo;
