import React, { ChangeEvent, FormEvent, useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import { HiOutlineMail } from "react-icons/hi";
import { MdLockOutline } from "react-icons/md";
import { Link, Redirect } from "react-router-dom";

import logo from "../assets/3wo-logo-transparent.png";
import firebaseAuth from "../config/firebase";
import { useAppContext } from "../utils/contextLib";

const LoginPage = function () {
  const [formData, setFormData] = useState({
    emailAddress: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [loginError, setLoginError] = useState<string[] | string>([]);

  const handleLogin = async () => {
    setLoading(true);
    const { emailAddress, password } = formData;

    try {
      // If Email is not verified yet.
      // if (isEmailVerified && !isEmailVerified.emailVerified) {
      //   setShowSendNewLinkURL(true);
      //   throw new Error(
      //     `Verifieer jouw e-mailadres eerst. Volg de stappen uit de e-mail die we hebben verzonden toen je registreerde. `
      //   );
      // }

      await signInWithEmailAndPassword(firebaseAuth, emailAddress, password)
        .then(() => {
          userHasAuthenticated(true);
          setLoading(false);
        })
        .catch(() => {
          const message =
            "Inloggen mislukt. Ongeldig e-mail of wachtwoord opgegeven.";
          setLoading(false);
          setLoginError(message);
          userHasAuthenticated(false);
        });
    } catch (error: any) {
      // Catch error if email is not verified yet

      setLoading(false);
      setLoginError(error.message);
      userHasAuthenticated(false);
      setLoginError([]);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    // const { email } = formData;

    // We check if the email is verified in our db. If not, let user first do
    // that via the email they received when registered
    // checkIfMailIsVerified({
    //   variables: {
    //     email: normalizedEmail,
    //   },
    // });

    await handleLogin();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError("");
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if (!loading && isAuthenticated) return <Redirect to="/" />;

  return (
    <div className="bg-black min-w-screen min-h-screen flex flex-col items-center md:justify-center p-5">
      <div className="md:hidden flex z-0 mb-4 h-40 align-center flex-col justify-center ">
        <div className="h-20">
          <img src={logo} alt="" width={100} />
        </div>
      </div>
      <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-5xl max-h-full">
        <div className="md:flex w-full">
          <div className="hidden md:block w-1/2 bg-white py-10 px-10">
            <div className="flex h-full justify-center items-center">
              <img
                src={logo}
                alt="logo"
                width={300}
                style={{ filter: "invert(100%)" }}
              />
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-1/2 py-10 px-6 md:px-10"
          >
            <div className="text-center mb-5 md:mb-10">
              <h1 className="font-bold text-3xl text-gray-900 uppercase">
                Login
              </h1>
              <p>Vul jouw gegevens in om in te loggen</p>
            </div>
            <div>
              <div className="flex -mx-3">
                <div className="w-full px-3">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    E-mailadres
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <HiOutlineMail className="text-lg text-gray-400" />
                      </div>
                      <input
                        type="email"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="johnsmith@example.com"
                        name="emailAddress"
                        onChange={handleInputChange}
                        value={formData.emailAddress}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3">
                  <label
                    htmlFor="password"
                    className="text-xs font-semibold px-1"
                  >
                    Wachtwoord
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <MdLockOutline className="text-gray-400 text-lg" />
                      </div>

                      <input
                        type="password"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="************"
                        name="password"
                        onChange={handleInputChange}
                        value={formData.password}
                      />
                    </div>
                  </label>
                  <div className="text-xs -mt-4 mb-6 ml-2 underline">
                    <Link to="/reset-password">Wachtwoord vergeten?</Link>
                  </div>
                </div>
              </div>

              {Array.isArray(loginError) && loginError?.length ? (
                loginError.map((e, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="text-red-600 font-semibold">
                    {e}
                  </div>
                ))
              ) : (
                <div className="text-red-600 font-semibold">{loginError}</div>
              )}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-2">
                  <button
                    type="submit"
                    className="block w-full max-w-xs mx-auto bg-blue-800 hover:bg-blue-700 focus:bg-blue-700 text-white rounded-lg px-3 py-3 font-semibold uppercase"
                  >
                    LOGIN
                  </button>
                  <Link
                    className="text-sm text-black font-extralight flex justify-center mt-4"
                    to="/register"
                  >
                    Of klik hier om in te registreren
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
