import React from "react";

import { Link } from "react-router-dom";

const PageNotFound = function () {
  return (
    <div className="bg-red-koekemannetje h-screen w-screen justify-center flex flex-col">
      <div className="flex-col text-center">
        <p className="text-3xl pb-4">Page Not Found</p>
        <p className="text-lg">If problem persists, please contact support</p>
        <div className="justify-center flex flex-row">
          <Link to="/member">
            <div className="bg-black text-white px-5 py-3 w-36 my-3">Home</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
