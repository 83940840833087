import React from "react";

import Faq from "../components/FAQ/Faq";
import Activity from "../components/MembersOverviewComponents/Activity/Activity";
import Footer from "../components/MembersOverviewComponents/Footer/Footer";
import Header from "../components/MembersOverviewComponents/Header/Header";
import MembersNavbar from "../components/MembersOverviewComponents/MembersNavbar/MembersNavbar";
import MyAccount from "../components/MembersOverviewComponents/MyAccount/MyAccount";
import TextSection from "../components/MembersOverviewComponents/TextSection/TextSection";
import ProductSlider from "../components/ProductsSlider/Slider";
import {
  useGetPiggyMemberCreditBalanceQuery,
  useGetUserOverviewDataQuery,
} from "../graphql/__generated__/graphql_types";
import { useAppContext } from "../utils/contextLib";

const MemberOverviewPage = function () {
  const { firebaseUser } = useAppContext();

  const { data, loading } = useGetUserOverviewDataQuery({
    variables: {
      email: firebaseUser?.email as string,
    },
    // We need this, becauase some weird error occur when logging out...
    skip: !firebaseUser,
  });

  const { data: PiggyMemberCreditData, loading: piggyLoading } =
    useGetPiggyMemberCreditBalanceQuery({
      variables: {
        emailAddress: firebaseUser?.email as string,
      },
    });

  if (loading || piggyLoading) return <h1>Loading....</h1>;
  if (!data) return null;

  return (
    <>
      <MembersNavbar />
      <Header
        totalAmountOfPoints={
          PiggyMemberCreditData?.getPiggyMemberCreditBalance.balance || 0
        }
        name={data!.getUserOverviewData.firstName}
      />
      <Activity
        points={data!.getUserOverviewData.Points}
        payments={data!.getUserOverviewData.Payments}
      />
      <TextSection />
      <ProductSlider />
      <MyAccount
        firstName={data!.getUserOverviewData.firstName}
        lastName={data!.getUserOverviewData.lastName}
        phoneNumber={data!.getUserOverviewData.phoneNumber}
      />
      <Faq />
      <Footer />
    </>
  );
};

export default MemberOverviewPage;
