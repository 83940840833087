import React from "react";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import AdminRouteGuard from "./components/RouteGuard/AdminRouteGuard";
import RouteGuard from "./components/RouteGuard/RouteGuard";
import AddPointsPage from "./pages/AddPoints";
import CardConnectedPage from "./pages/CardConnectedPage";
import ForgottenPasswordPage from "./pages/ForgottenPassword";
import HomeOverviewPage from "./pages/HomeOverview";
import InterfaceSearchPage from "./pages/InterfaceSearchPage";
import LoginPage from "./pages/LoginPage";
import MemberOverviewPage from "./pages/MemberOverViewPage";
import PageNotFound from "./pages/PageNotFound";
import RedeemPointsPage from "./pages/RedeemPoints";
import RegisterPage from "./pages/RegisterPage";

const Routes = function () {
  return (
    <Router>
      <Switch>
        <RouteGuard exact path="/">
          <Redirect to="/member" />
        </RouteGuard>
        <AdminRouteGuard exact path="/interface">
          <HomeOverviewPage />
        </AdminRouteGuard>
        <AdminRouteGuard exact path="/interface/add-points">
          <AddPointsPage />
        </AdminRouteGuard>
        <AdminRouteGuard exact path="/interface/redeem-points">
          <RedeemPointsPage />
        </AdminRouteGuard>
        <AdminRouteGuard exact path="/interface/search">
          <InterfaceSearchPage />
        </AdminRouteGuard>
        <RouteGuard exact path="/member">
          <MemberOverviewPage />
        </RouteGuard>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/reset-password">
          <ForgottenPasswordPage />
        </Route>
        <Route exact path="/register">
          <RegisterPage />
        </Route>
        <Route exact path="/register/c=:card">
          <RegisterPage />
        </Route>
        <Route exact path="/card-connected">
          <CardConnectedPage />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
