import React from "react";

import { FaRegUserCircle } from "react-icons/fa";

const UnknownResponse = function () {
  return (
    <>
      <div className="bg-red-unknown h-1/2 rounded-t-xl flex flex-col items-center">
        <div className=" -mt-6 flex justify-center text-red-800 flex-col">
          <div className="flex justify-center w-auto">
            <div className="bg-red-unknown rounded-full p-2">
              <FaRegUserCircle size={35} />
            </div>
          </div>
          <p className="text-lg font-semibold">Betaling herkend</p>
        </div>
        <div className="text-center h-full flex items-center p-10 pt-4">
          <h1 className="text-2xl font-semibold text-white">
            <span>
              Eerste gebruik <br /> Niet gekoppeld
            </span>
          </h1>
        </div>
      </div>
      <div className="h-1/3 px-6 pt-2 flex items-center">
        <p className="italic text-gray-400 font-extralight text-center text-sm">
          Vul rechts in het zoekveld een mobiel telefoonnumer in om een klant
          uit te nodigen voor het spaarprogramma of om de betaalmethode te
          koppelen aan een reeds bestaande member.
        </p>
      </div>
    </>
  );
};

export default UnknownResponse;
