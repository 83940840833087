import { useContext, createContext } from "react";

import { User } from "firebase/auth";

type ContextProps = {
  isAuthenticated: boolean;
  userHasAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  firebaseUser: User | null | undefined;
};

export const AppContext = createContext<ContextProps>({
  firebaseUser: undefined,
  isAuthenticated: false,
  userHasAuthenticated: () => null,
});

export function useAppContext() {
  return useContext(AppContext);
}
