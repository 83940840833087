import React, { Dispatch, SetStateAction } from "react";

import Modal from "react-modal";
import { toast } from "react-toastify";

import { useDeleteUserMutation } from "../../graphql/__generated__/graphql_types";

const DeleteAccountModal = function (props: {
  isOpen: boolean;
  emailAddress: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { isOpen, emailAddress, setIsModalOpen } = props;

  const [deleteUserMutation] = useDeleteUserMutation({
    variables: { userEmail: emailAddress },
  });

  const deleteUserAccount = () => {
    deleteUserMutation()
      .then(() => {
        localStorage.clear();
        window.location.assign("/login");
      })
      .catch(() => toast.error("Er ging iets mis..."));
  };

  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsModalOpen(!isOpen)}
      className="bg-white rounded-2xl absolute m-auto left-2 right-2 md:left-20 md:right-20 top-10 max-w-5xl"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)" } }}
    >
      <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl sm:w-96 md:w-full max-h-full p-10">
        <div className="w-full justify-center">
          <div className="text-center">
            <h2 className="text-black">
              U staat op het punt om uw account te verwijderen. <br />{" "}
            </h2>
            <p>
              Al uw gespaarde punten worden hiermee verwijderd, alsmede uw
              gekoppelde kaarten. <br /> Bevestig hieronder om uw account
              definitief te verwijderen.
            </p>
          </div>
          <div className="text-center">
            <button
              onClick={() => setIsModalOpen(false)}
              type="button"
              className="px-6 py-2 text-black my-8 rounded-lg underline text-sm"
            >
              Annuleren
            </button>
            <button
              onClick={deleteUserAccount}
              type="button"
              className="bg-red-600 px-6 py-2 text-sm text-white my-8 rounded-lg"
            >
              Verwijder Account
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
