import React from "react";

import { AdyenStatusNotification } from "../../graphql/__generated__/graphql_types";
import InitialState from "./InitialState/InitialState";
import SuccessResponse from "./SuccessResponse/SuccessResponse";
import UnknownResponse from "./Unknown/UnknownResponse";
import UnregisteredResponse from "./Unregistered/UnregisteredResponse";

const ClientInformation = function (props: {
  memberInformation?: AdyenStatusNotification;
}) {
  const { memberInformation } = props;

  // When terminal is triggered, we receive status "pending" from backend. Show
  // loading text and spinner in InitialState component
  if (memberInformation?.status === "pending")
    return (
      <div className="flex flex-col bg-white rounded-xl md:h-tablet lg:h-laptop">
        <InitialState loading />
      </div>
    );

  return (
    <div className="flex flex-col bg-white rounded-xl md:h-tablet lg:h-laptop">
      {/* Inital state. Waiting for terminal to be triggered */}
      {!memberInformation && <InitialState loading={false} />}

      {/* User is found and recognized */}
      {memberInformation?.user && (
        <SuccessResponse userData={memberInformation} />
      )}

      {/* Card is used multiple times, but not registered to user yet */}
      {!memberInformation?.user && memberInformation?.isCardRecognized && (
        <UnregisteredResponse
          totalCountOfPayments={memberInformation.totalCountOfTransactions}
        />
      )}

      {/* First time payment */}
      {memberInformation && !memberInformation?.isCardRecognized && (
        <UnknownResponse />
      )}

      <p
        className={`${
          memberInformation?.orderId ? "visible" : "invisible"
        }  text-center text-sm flex flex-col flex-1 justify-center text-gray-400`}
      >
        OrderID: {memberInformation?.orderId} <br />
        <span className="text-xs">
          {new Date(memberInformation?.date!).toLocaleString("nl-NL", {
            dateStyle: "medium",
            timeStyle: "medium",
          })}
        </span>
      </p>
    </div>
  );
};

export default ClientInformation;

ClientInformation.defaultProps = {
  memberInformation: undefined,
};
