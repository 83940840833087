import React from "react";

import { FaRegAddressCard } from "react-icons/fa";
import Modal from "react-modal";
import { toast } from "react-toastify";

import {
  useConnectCardToUserMutation,
  User,
} from "../../graphql/__generated__/graphql_types";

const ConnectModal = function (props: {
  isOpen: boolean;
  closeModal: () => void;
  userData: User | undefined;
  orderId: string | undefined | null;
  reset: () => void;
}) {
  const { isOpen, closeModal, userData, orderId, reset } = props;
  const [connectCard] = useConnectCardToUserMutation();

  const connectCardWithUser = async () => {
    await connectCard({
      variables: {
        orderId: orderId as string,
        userEmail: userData?.email as string,
      },
    }).then(
      () => {
        closeModal();
        toast.success("De uitnodiging is verzonden via SMS!");
        reset();
      },
      () => {
        toast.error("Telefoonnummer is niet valide.");
        closeModal();
      }
    );
  };

  // TODO: When connecting, send email to verify user. Now it is connected straight away.

  Modal.setAppElement("#root");
  return (
    <div>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={closeModal}
        className="bg-white rounded-2xl absolute left-20 right-20 top-10"
        style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 11 } }}
      >
        <div className="flex flex-col items-center">
          <div className="bg-green-success w-full rounded-t-2xl h-16 flex flex-col items-center">
            <div className=" bg-green-success w-14 h-14 p-3 -mt-6 rounded-full flex justify-center">
              <FaRegAddressCard color="white" size={30} />
            </div>
            <div className="text-xl text-white">Koppel kaart aan gebruiker</div>
          </div>
          <div className="p-8 flex flex-col max-w-3xl text-center">
            U staat op het punt om de kaart van de laatste betaling te koppelen
            aan:
            <div className="mt-2 text-lg font-bold ">
              <div>{`${userData?.firstName} ${userData?.lastName}`}</div>
              <div>{userData?.phoneNumber}</div>
              <div>{userData?.email}</div>
            </div>
            <div className="mt-2 px-16 text-gray-600">
              De klant krijgt een verificatie email en bij een correcte
              afhandeling van de email is de kaart gekoppeld.
            </div>
            <div className="flex justify-evenly mt-5 min-w-full ">
              <button
                className="bg-gray-400 px-5 py-1 rounded text-white"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 px-5 py-1 rounded text-white"
                type="button"
                onClick={connectCardWithUser}
              >
                Bevestig
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConnectModal;
