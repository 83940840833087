import React, { ChangeEvent, FormEvent, useState } from "react";

import { sendPasswordResetEmail } from "firebase/auth";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import logo from "../assets/3wo-logo-transparent.png";
import firebaseAuth from "../config/firebase";

const ForgottenPasswordPage = function () {
  const [email, setEmail] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    firebaseAuth.languageCode = "nl";
    await sendPasswordResetEmail(firebaseAuth, email, {
      url: `https://${window.location.host}/login`,
    })
      .then(() => {
        const message = `E-mail verzonden naar ${email}!`;
        toast.success(message);
        // setResetTriggered(true);
        setEmail("");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          const message = `E-mail is geen geldig e-mailadres!`;
          toast.error(message);
        } else if (error.code === "auth/user-not-found") {
          const message = `Dit e-mailadres bestaat niet...`;
          toast.error(message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-black min-w-screen min-h-screen flex flex-col items-center md:justify-center p-5">
        <div className="md:hidden flex justify-start z-0">
          <img src={logo} alt="" width={200} />
        </div>
        <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-5xl max-h-full">
          <div className="md:flex w-full">
            <div className="hidden md:block w-1/2 bg-white py-10 px-10">
              <div className="flex h-full justify-center items-center">
                <img src={logo} alt="" width={300} />
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="w-full md:w-1/2 py-10 px-6 md:px-10"
            >
              <div className="text-center mb-5 md:mb-10">
                <h1 className="font-bold text-3xl text-gray-900 uppercase">
                  Wachtwoord resetten
                </h1>
                <p>
                  Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen
                </p>
              </div>
              <div>
                <div className="w-full px-3">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    E-mailadres
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <HiOutlineMail className="text-lg text-gray-400" />
                      </div>
                      <input
                        type="email"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="johnsmith@example.com"
                        name="emailAddress"
                        value={email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </label>
                </div>
              </div>

              <div className="flex -mx-3">
                <div className="w-full px-3 mb-2">
                  <button
                    type="submit"
                    className="block w-full max-w-xs mx-auto bg-blue-800 hover:bg-blue-700 focus:bg-blue-700 text-white rounded-lg px-3 py-3 font-semibold uppercase"
                  >
                    Submit
                  </button>
                  <Link
                    className="text-sm text-black font-extralight flex justify-center mt-4"
                    to="/login"
                  >
                    Of klik hier om in te loggen
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgottenPasswordPage;
