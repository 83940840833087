import React, { ChangeEvent, useState } from "react";

import { AiOutlineUserAdd } from "react-icons/ai";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { useSendRegisterSmsMutation } from "../../graphql/__generated__/graphql_types";

const CreateUserModal = function (props: {
  isOpen: boolean;
  closeModal: () => void;
  phoneNumber: string;
  cardAlias: string | undefined | null;
  reset: () => void;
}) {
  const { isOpen, closeModal, phoneNumber, cardAlias, reset } = props;
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(phoneNumber);

  const [sendSMS] = useSendRegisterSmsMutation();

  const sendRegisterSMS = async () => {
    // TODO: Handle flow if no cardAlias is selected. IE if no order is selected, but directly searching

    await sendSMS({
      variables: {
        cardAlias: cardAlias as string,
        to: phoneNumber,
      },
    }).then(
      () => {
        reset();
        closeModal();
        toast.success("De uitnodiging is verzonden via SMS!");
      },
      () => {
        toast.error("Telefoonnummer is niet valide.");
        closeModal();
      }
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { value } = target;

    setMobilePhoneNumber(value);
  };

  Modal.setAppElement("#root");
  return (
    <div>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={closeModal}
        className="bg-white rounded-2xl absolute left-20 right-20 top-10"
        style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 11 } }}
      >
        <div className="flex flex-col items-center">
          <div className="bg-green-success w-full rounded-t-2xl h-16 flex flex-col items-center">
            <div className=" bg-green-success w-14 h-14 p-3 -mt-6 rounded-full flex justify-center">
              <AiOutlineUserAdd color="white" size={30} />
            </div>
            <div className="text-xl text-white">
              Koppel kaart aan nieuwe gebruiker
            </div>
          </div>

          <div className="p-8 flex flex-col max-w-2xl text-center">
            U staat op het punt om een gebruiker uit te nodigen met
            telefoonnummer:
            <input
              value={mobilePhoneNumber}
              name="mobilePhone"
              onChange={handleInputChange}
              className="text-center self-center border-solid border border-gray-400 my-3"
            />
            De klant krijgt een verificatie sms en bij een correcte afhandeling
            van de sms is de kaart gekoppeld.
            <div className="flex justify-evenly mt-5 min-w-full">
              <button
                className="bg-gray-400 px-5 py-1 rounded text-white"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 px-5 py-1 rounded text-white"
                type="button"
                onClick={sendRegisterSMS}
              >
                Bevestig
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateUserModal;
