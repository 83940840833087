import React from "react";

import { FaCheck } from "react-icons/fa";
import { HiArrowLeft } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

import NumberPadKey from "./NumberPadKey/NumberPadKey";

const NumberPad = function (props: {
  displayPriceInCents: string;
  setDisplayPrice: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => void;
  displayInEuro: boolean;
}) {
  const { displayPriceInCents, displayInEuro, handleSubmit, setDisplayPrice } =
    props;

  const displayPriceToFormattedNumber = displayInEuro
    ? (parseFloat(displayPriceInCents || "0") / 100).toFixed(2)
    : parseInt(displayPriceInCents, 10) || 0;

  const savePayment = () => {
    if (
      displayInEuro &&
      parseFloat(displayPriceToFormattedNumber as string) < 1
    ) {
      toast.error("Het bedrag is te laag. Voer minimaal 1 euro in", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }
    handleSubmit();
  };

  return (
    <>
      <div
        className="bg-white bg-opacity-40 rounded-full p-3 w-full h-20 text-center text-white text-5xl "
        id="numPad"
      >
        {displayInEuro && "€"} {displayPriceToFormattedNumber}
      </div>

      <div className="grid grid-cols-4 mt-6 grid-rows-4 gap-2 gap-x-2 lg:gap-y-10">
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("1"))}
        >
          1
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("2"))}
        >
          2
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("3"))}
        >
          3
        </NumberPadKey>
        <button
          type="button"
          className="rounded-full bg-yellow-500 w-20 h-20 p-5 mx-0 justify-self-center text-xl font-bold focus:outline-none"
          onClick={() => setDisplayPrice(displayPriceInCents.slice(0, -1))}
        >
          <HiArrowLeft size={35} color="white" />
        </button>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("4"))}
        >
          4
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("5"))}
        >
          5
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("6"))}
        >
          6
        </NumberPadKey>
        <button
          className="rounded-full bg-red-500 w-20 p-4 h-20  mx-0 justify-self-center text-xl font-bold focus:outline-none"
          onClick={() => setDisplayPrice("0")}
          type="button"
        >
          <IoMdClose size={45} color="white" />
        </button>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("7"))}
        >
          7
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("8"))}
        >
          8
        </NumberPadKey>
        <NumberPadKey
          onClick={() => setDisplayPrice(displayPriceInCents.concat("9"))}
        >
          9
        </NumberPadKey>

        <button
          type="button"
          className="rounded-3xl rounded-b-lg bg-green-500 w-20 justify-self-center row-span-2 text-xl font-bold focus:outline-none"
          onClick={savePayment}
        >
          <div className="h-full flex justify-center items-center">
            <FaCheck color="white" size={35} />
          </div>
        </button>
        <button
          type="button"
          className="rounded-full bg-white h-20 col-span-2 mx-0 text-xl font-bold focus:outline-none "
          onClick={() => setDisplayPrice(displayPriceInCents.concat("0"))}
        >
          0
        </button>
        <button
          type="button"
          className="rounded-l-3xl bg-green-500 h-20 -mr-9 ml-3 text-xl font-bold focus:outline-none"
          onClick={savePayment}
        >
          {" "}
        </button>
      </div>
    </>
  );
};

export default NumberPad;
