import React from "react";

import { GiCash } from "react-icons/gi";
import { useHistory } from "react-router-dom";

const ManualPointsButton = function ({
  enabled,
  customerData,
  totalAmountOfPoints,
}: {
  customerData: {
    firstName: string;
    lastName: string;
    email: string;
  };
  enabled: boolean;
  totalAmountOfPoints: number;
}) {
  const history = useHistory();

  return (
    <button
      type="button"
      disabled={!enabled}
      className="disabled:opacity-50 bg-blue-loyyo-light-color rounded-2xl w-1/2 mr-2 px-5 text-center flex flex-col items-center justify-center font-semibold"
      onClick={() =>
        history.push("/interface/add-points", {
          customerData,
          totalAmountOfPoints,
        })
      }
    >
      <GiCash size={45} color="white" />
      <div className="text-white text-xl text-center mt-2">Voeg punten toe</div>
    </button>
  );
};

export default ManualPointsButton;
