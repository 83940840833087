import React from "react";

import { Points } from "../../../graphql/__generated__/graphql_types";

const Activity = function ({
  points,
  payments,
}: {
  points: Points[];
  payments: { id: string; amount: number }[];
}) {
  return (
    <div
      id="activity"
      className="bg-black text-white p-8 md:flex md:justify-center"
    >
      <div className="md:w-2/3">
        <p className="text-center text-2xl font-bold">Activiteit</p>
        <table className="w-full my-6">
          <tbody>
            <tr className="justify-between w-full h-10">
              <th className="text-left">Datum</th>
              <th className="text-center">Amount</th>
              <th className="text-right">Punten</th>
            </tr>
            {points
              .slice()
              .sort(
                (a, b) =>
                  new Date(b.createdAt).valueOf() -
                  new Date(a.createdAt).valueOf()
              )
              .slice(0, 8)
              .map(({ amount, createdAt, id, paymentId }) => {
                const payment = payments.find((p) => p.id === paymentId);
                return (
                  <tr
                    key={id}
                    className="text-white border-b-2 border-white border-dotted h-14 pointsTableRow"
                  >
                    <td>{new Date(createdAt).toLocaleDateString("NL")}</td>

                    <td className="text-center">
                      {payment?.amount
                        ? `${(payment.amount / 100).toLocaleString()}€`
                        : "-"}
                    </td>
                    <td className="text-right">{amount}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Activity;
