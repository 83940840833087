import React, { useEffect } from "react";

import { FaCashRegister } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoArrowBackSharp } from "react-icons/io5";
import { toast } from "react-toastify";

import logo from "../../assets/3wo-logo-black-bg.png";
import {
  useGetTerminalListQuery,
  useTriggerTerminalForPaymentMutation,
} from "../../graphql/__generated__/graphql_types";

const Navbar = function ({
  backIcon,
  selectedTerminal,
  selectTerminal,
}: {
  backIcon: boolean;
  selectedTerminal?: string;
  selectTerminal?: (terminalId: string) => void;
}) {
  const [triggerTerminal] = useTriggerTerminalForPaymentMutation();
  const { data } = useGetTerminalListQuery();

  const refreshInfo = () => {
    window.location.assign("/interface");
  };

  useEffect(() => {
    if (data?.getTerminalList.length && !backIcon) {
      selectTerminal!(data.getTerminalList[0].terminalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getTerminalList]);

  console.log({ selectedTerminal });
  const triggerPayment = async () => {
    if (selectedTerminal) {
      await triggerTerminal({
        variables: {
          terminalId: selectedTerminal!,
        },
      })
        .then(() => console.log("Hallo"))
        .catch((e) => {
          toast.error(
            "Er ging iets mis. Is de juiste terminal geselecteerd en staat de terminal aan?"
          );
          console.log({ e });
        });
    } else {
      toast.error("Selecteer een terminal");
    }
  };

  return (
    <div className="w-full bg-white h-20 justify-between flex">
      <div className="m-3 lg:m-4 ml-4 mr-0 w-40 flex">
        <button onClick={refreshInfo} type="button">
          {backIcon ? (
            <IoArrowBackSharp size={25} />
          ) : (
            <HiOutlineRefresh size={25} />
          )}
        </button>
      </div>
      {!backIcon ? (
        <div
          role="button"
          onKeyPress={triggerPayment}
          tabIndex={0}
          onClick={triggerPayment}
          className="rounded-full flex justify-center py-2"
        >
          <img src={logo} alt="logo" />
        </div>
      ) : (
        <div className="rounded-full flex justify-center py-2">
          <img src={logo} alt="logo" />
        </div>
      )}
      <div className="lg:m-4 ml-4 w-40 flex items-center">
        <div>
          <button className="-ml-20" onClick={triggerPayment} type="button">
            {!backIcon && <FaCashRegister size={28} />}
          </button>
        </div>
        {!backIcon && selectTerminal ? (
          <select
            style={{ textAlignLast: "right" }}
            className="my-auto w-40 mr-4 border-transparent"
            value={selectedTerminal}
            onChange={(e) => selectTerminal(e.target.value)!}
          >
            {data?.getTerminalList.map((terminal) => (
              <option key={terminal.terminalId} value={terminal.terminalId}>
                {terminal.name}
              </option>
            ))}
          </select>
        ) : (
          <div className="w-40 mr-4"> </div>
        )}
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  selectedTerminal: undefined,
  selectTerminal: undefined,
};

export default Navbar;
