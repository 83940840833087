/* eslint-disable no-nested-ternary */
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react";

import { useCombobox } from "downshift";
import { AiOutlineUserAdd } from "react-icons/ai";
import { VscSearch } from "react-icons/vsc";

import {
  User,
  useSearchUsersLazyQuery,
} from "../../graphql/__generated__/graphql_types";
import ConnectModal from "../Modal/ConnectModal";
import CreateUserModal from "../Modal/CreateUserModal";

const SearchBox = forwardRef(
  (
    props: {
      orderId: string | undefined | null;
      cardAlias: string | undefined | null;
      setUserFromSearch: React.Dispatch<
        React.SetStateAction<{
          firstName: string;
          lastName: string;
          email: string;
        }>
      >;
      isCardAlreadyConnected: boolean;
    },
    ref: any
  ) => {
    const searchRef = useRef<any>();

    // const itemToString = (item: any) => (item ? item.name : "");

    const { cardAlias, orderId, setUserFromSearch, isCardAlreadyConnected } =
      props;
    const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
    const [isCreateUserModalOpeen, setIsCreateUserModalOpen] = useState(false);
    const [users, setUsers] = useState<User[]>([]);

    const [searchUsers, { data, loading, error }] = useSearchUsersLazyQuery({
      fetchPolicy: "cache-and-network",
    });

    const [user, setUser] = useState<User>();

    useEffect(() => {
      if (data?.searchFilterUsers) {
        setUsers(data?.searchFilterUsers);
      }
    }, [data, data?.searchFilterUsers]);

    const openConnectModal = (userData: User) => {
      setIsConnectModalOpen(true);
      setUser(userData);
    };

    const {
      isOpen,
      getInputProps,
      getMenuProps,
      getItemProps,
      getComboboxProps,
      selectedItem,
      openMenu,
      reset,
    } = useCombobox({
      items: users,
      // itemToString,
      onInputValueChange: async ({ inputValue }) => {
        let phone;
        if (inputValue && inputValue.length < 5) return;

        if (inputValue?.startsWith("06")) {
          phone = `+316${inputValue.slice(2)}`;
        } else phone = inputValue;

        searchUsers({
          variables: {
            filter: phone || " ",
          },
        });
      },
      onSelectedItemChange: () => {
        reset();
      },
    });

    // This is to clear the state from parent when payment is coming in.
    useImperativeHandle(ref, () => ({
      clearState: () => {
        searchRef.current = reset();
      },
    }));

    useEffect(() => {
      if (selectedItem) {
        setUserFromSearch({
          email: selectedItem.email,
          firstName: selectedItem.firstName,
          lastName: selectedItem.lastName,
        });
      }
    }, [selectedItem, setUserFromSearch]);

    return (
      <>
        <div
          className="bg-white rounded-xl h-20 flex items-center p-6"
          {...getComboboxProps()}
        >
          <input
            className="text-lg italic leading-8 w-full rounded-xl border-none h-full"
            placeholder="Vul mobiel nummer in"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps({
              onFocus: () => {
                if (!isOpen) {
                  openMenu();
                }
              },
            })}
            // value={getInputProps().value}
            type="tel"
          />
          <VscSearch size={30} />{" "}
        </div>

        <ul {...getMenuProps()}>
          {!isOpen ? null : (
            <div className="p-0 m-0">
              <div className="bg-white w-auto h-72 overflow-scroll border rounded-2xl -mb-40 md:-mb-16 relative z-10">
                {loading ? (
                  <div className="p-3">Loading....</div>
                ) : error ? (
                  <>
                    <div className="p-3">Error....</div>
                    <div className="p-3">{JSON.stringify(error)}</div>
                  </>
                ) : users.length ? (
                  <div className="box-border relative mt-0 overflow-x-hidden overflow-y-auto">
                    {users.map((item, index) => (
                      <li
                        key={`${item.email}${item.phoneNumber}`}
                        className="w-full p-3 bg-white border cursor-pointer"
                        {...getItemProps({
                          item,
                          index,
                          disabled: !!orderId,
                        })}
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex flex-wrap flex-col justify-between">
                            <div className="text-lg">{`${item.firstName} ${item.lastName}`}</div>
                            <div className="text-lg"> {item.phoneNumber} </div>
                          </div>
                          {orderId && !isCardAlreadyConnected && (
                            <button
                              className="flex flex-row bg-green-400 rounded-lg p-2 text-white items-center h-1/2"
                              type="button"
                              onClick={() => openConnectModal(item)}
                            >
                              Connect
                            </button>
                          )}
                        </div>
                      </li>
                    ))}
                  </div>
                ) : getInputProps().value.length < 5 ? (
                  <div className="flex items-center h-3/4">
                    <div className="self-center text-center flex-col w-full">
                      <p className="my-1">Voer minimaal 5 tekens in...</p>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center h-3/4">
                    <div className="self-center text-center flex-col w-full">
                      <p className="my-1">
                        Geen member gevonden met <br /> {getInputProps().value}
                      </p>
                      <button
                        type="button"
                        className="bg-blue-400 px-5 py-2 rounded-2xl text-white"
                        onClick={() => setIsCreateUserModalOpen(true)}
                      >
                        <AiOutlineUserAdd
                          size={25}
                          className="text-center w-full"
                        />
                        Stuur uitnodiging
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </ul>
        {isConnectModalOpen && (
          <ConnectModal
            isOpen={isConnectModalOpen}
            closeModal={() => setIsConnectModalOpen(false)}
            userData={user}
            orderId={orderId}
            reset={reset}
          />
        )}
        {isCreateUserModalOpeen && (
          <CreateUserModal
            cardAlias={!isCardAlreadyConnected ? cardAlias : ""}
            closeModal={() => setIsCreateUserModalOpen(false)}
            isOpen={isCreateUserModalOpeen}
            phoneNumber={getInputProps().value}
            reset={reset}
          />
        )}
      </>
    );
  }
);

SearchBox.displayName = "SearchBox";

export default SearchBox;
