/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import { toast } from "react-toastify";

import { useGetPiggyListOfShopsQuery } from "../../graphql/__generated__/graphql_types";

const ProductSlider = function () {
  const { data: piggyShopList, loading: piggyLoading } =
    useGetPiggyListOfShopsQuery();
  const [selected, setSelected] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
  };

  const handleClick = () => {
    if (selected) {
      window.open(`https://customer.piggy.eu/shops/${selected}`);
    } else {
      toast.error("Selecteer een locatie", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  if (piggyLoading) return <div>Loading...</div>;

  return (
    <div id="products" className="bg-black p-8 text-center flex justify-center">
      <div className="md:w-2/3">
        <p className="text-2xl mb-8 text-white font-bold">Wissel punten in</p>
        <p className="text-gray-400">
          Je spaart automatisch punten bij iedere aankoop. Deze punten kun je
          inwisselen voor kortingen of zelfs gratis drankjes of hapjes.
          Selecteer hieronder de locatie en druk op de &apos;Wissel punten
          in&apos; Knop ome te zien waar je voor kunt sparen.
        </p>
        <div className="flex flex-col sm:flex-row justify-center content-center overflow-y-auto pt-6 space-x-0 sm:space-x-3 space-y-6 sm:space-y-0">
          <div className="">
            <select
              id="list-shops"
              name="list-shops"
              className="block py-3 w-full bg-white border-0 rounded-md focus:outline-none focus:ring-indigo-500 "
              onChange={handleChange}
              defaultValue={selected}
            >
              <option value="">Selecteer hier een locatie</option>
              {piggyShopList?.getPiggyListOfShops.data.map(
                ({ name, uuid, id }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="h-full">
            <button
              type="button"
              className="px-8 py-3 text-white rounded-lg text-black bg-green-400 hover:bg-green-500"
              onClick={handleClick}
            >
              Wissel punten in!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
