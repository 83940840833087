import React from "react";

import { FiGift } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const RedeemButton = function ({
  customerData,
  enabled,
  totalAmountOfPoints,
}: {
  customerData: { firstName: string; lastName: string; email: string };
  enabled: boolean;
  totalAmountOfPoints: number;
}) {
  const history = useHistory();
  return (
    <button
      type="button"
      disabled={!enabled}
      className="disabled:opacity-50 bg-blue-loyyo-light-color rounded-2xl px-5 w-1/2 ml-2 text-center flex flex-col items-center justify-center font-semibold"
      onClick={() =>
        history.push("/interface/redeem-points", {
          customerData,
          totalAmountOfPoints,
        })
      }
    >
      <FiGift size={50} color="white" />
      <div className="text-white text-xl text-center mt-2">
        Wissel punten in
      </div>
    </button>
  );
};

export default RedeemButton;
