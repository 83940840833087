import React, { useState } from "react";

import { useAppContext } from "../../../utils/contextLib";
import ChangeAccountModal from "../../Modal/ChangeAccountModal";
import DeleteAccountModal from "../../Modal/DeleteAccountModal";

const MyAccount = function ({
  firstName,
  lastName,
  phoneNumber,
}: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const { firebaseUser } = useAppContext();

  console.log({ firebaseUser });

  return (
    <div
      id="account"
      className="bg-white text-center p-8 md:flex md:justify-center"
    >
      <div className="md:w-2/3">
        <p className="text-2xl font-bold">Mijn account</p>
        <table className="w-full my-6">
          <tbody>
            <tr className=" border-b-2 border-gray-600 border-dotted h-12 flex justify-between py-2">
              <td className="font-bold">Naam:</td>
              <td className="text-right">{`${firstName} ${lastName}`}</td>
            </tr>
            <tr className=" border-b-2 border-gray-600 border-dotted h-12 flex justify-between py-2">
              <td className="font-bold">Email:</td>
              <td className="text-right">{firebaseUser!.email} </td>
            </tr>
            <tr className=" border-b-2 border-gray-600 border-dotted h-12 flex justify-between py-2">
              <td className="font-bold">Telefoonnummer:</td>
              <td className="text-right">{phoneNumber} </td>
            </tr>
            {/* <tr className=" border-b-2 border-gray-600 border-dotted h-12 flex justify-between py-2">
            <td className="font-bold">Verjaardag:</td>
            <td className="text-right">7 juni 1984</td>
          </tr> */}
          </tbody>
        </table>
        <div>
          <button
            onClick={() => setIsDeleteAccountModalOpen(true)}
            type="button"
            className="px-6 py-2 text-red-500 my-8 rounded-lg underline text-sm"
          >
            Verwijder Account
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            type="button"
            className="bg-black px-6 py-2 text-sm text-white my-8 rounded-lg"
          >
            Update Profiel
          </button>
        </div>
      </div>
      {isModalOpen && (
        <ChangeAccountModal
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          accountData={{
            firstName,
            lastName,
            phoneNumber,
            email: firebaseUser!.email as string,
          }}
        />
      )}

      {isDeleteAccountModalOpen && (
        <DeleteAccountModal
          isOpen={isDeleteAccountModalOpen}
          setIsModalOpen={setIsDeleteAccountModalOpen}
          emailAddress={firebaseUser?.email as string}
        />
      )}
    </div>
  );
};

export default MyAccount;
