/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */

import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirebaseEnvironmentVariable } from "../utils/getEnv";
import { devCloud, localDev, prodCloud, testEnv } from "./firebase-configs";

const environment = getFirebaseEnvironmentVariable();

// TODO: Remove logs after testing.
switch (environment) {
  case "dev-cloud":
    console.log("The environment is: ", environment);
    initializeApp(devCloud);
    break;
  case "local-dev":
    console.log("The environment is: ", environment);
    initializeApp(localDev);
    break;
  case "test-env":
    console.log("The environment is: ", environment);
    initializeApp(testEnv);
    break;
  case "prod-cloud":
    console.log("Env: ", environment);
    initializeApp(prodCloud);
    break;

  default:
    console.log("The environment is: ", environment);
    initializeApp(localDev);
    console.log("NO ENV SET!");
    break;
}

const firebaseAuth = getAuth(getApp());

export default firebaseAuth;
