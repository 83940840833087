import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
} from "react";

import { ImMobile } from "react-icons/im";
import { MdPersonOutline } from "react-icons/md";
import Modal from "react-modal";
import { toast } from "react-toastify";

import {
  useUpdateUserAccountMutation,
  GetUserOverviewDataDocument,
} from "../../graphql/__generated__/graphql_types";

interface IObjectKeys {
  [key: string]: string | number;
}

interface IAccountData extends IObjectKeys {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

const ChangeAccountModal = function (props: {
  isOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  accountData: IAccountData;
}) {
  const [updateUser] = useUpdateUserAccountMutation();
  const { isOpen, setIsModalOpen, accountData } = props;
  const [initialAccountData] = useState(accountData);
  const [accountFormData, setAccountFormData] = useState(accountData);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAccountFormData({
      ...accountFormData,
      [name]: value,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // TODO: When removing Name from database, also change object on backend for testing if equal.
    updateUser({
      variables: {
        userEmailFromClient: initialAccountData.email,
        updateUserAccountInput: {
          email: accountFormData.email,
          firstName: accountFormData.firstName,
          lastName: accountFormData.lastName,
          phoneNumber: accountFormData.phoneNumber,
        },
      },
      refetchQueries: [
        {
          query: GetUserOverviewDataDocument,
          variables: {
            email: accountFormData.email,
          },
        },
      ],
    })
      .then(({ data }) => {
        toast.success("Gegevens zijn aangepast");

        if (data?.updateUserAccountData.isEmailChanged)
          window.location.assign("/");

        setIsModalOpen(!isOpen);
      })
      .catch((error) => {
        let message;
        if (
          error?.graphQLErrors[0]?.extensions?.exception?.response?.message
            ?.length
        ) {
          [message] =
            error.graphQLErrors[0].extensions.exception.response.message;
        } else if (
          error?.graphQLErrors[0]?.extensions?.exception?.code === "P2002"
        ) {
          message = `${error.graphQLErrors[0]?.extensions?.exception?.meta?.target[0]} is al in gebruik door een account.`;
        } else {
          message = error.message;
        }

        toast.error(message);
      });
  };

  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsModalOpen(!isOpen)}
      className="bg-white rounded-2xl absolute m-auto left-2 right-2 md:left-20 md:right-20 top-10 max-w-5xl"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)" } }}
    >
      <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl sm:w-96 md:w-full overflow-hidden max-h-full">
        <div className="md:flex w-full justify-center">
          <form
            onSubmit={handleSubmit}
            className="w-full lg:w-3/4 flex flex-col justify-center py-10 px-6 md:px-10 text-center"
          >
            <div className="text-center mb-5 md:mb-10">
              <h1 className="font-bold text-3xl text-gray-900 uppercase">
                Account
              </h1>
              <p>Verander jouw gegevens.</p>
            </div>
            <div className="flex -mx-3">
              <div className="w-1/2 px-3">
                <label
                  htmlFor="first-name"
                  className="text-xs font-semibold px-1"
                  aria-controls="first-name"
                >
                  Voornaam
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <MdPersonOutline className="text-gray-400 text-lg" />
                    </div>
                    <input
                      type="text"
                      className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                      placeholder="John"
                      id="first-name"
                      name="firstName"
                      onChange={handleInputChange}
                      value={accountFormData.firstName}
                    />
                  </div>
                </label>
              </div>

              <div className="w-1/2 px-3">
                <label
                  htmlFor="last-name"
                  className="text-xs font-semibold px-1"
                >
                  Achternaam
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <MdPersonOutline className="text-gray-400 text-lg" />
                    </div>
                    <input
                      type="text"
                      className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                      placeholder="Smith"
                      id="last-name"
                      name="lastName"
                      onChange={handleInputChange}
                      value={accountFormData.lastName}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="flex -mx-3 text-justify">
              <div className="w-full px-3">
                <label htmlFor="phone" className="text-xs font-semibold px-1">
                  Mobiel nummer
                  <div className="flex">
                    <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                      <i className="mdi mdi-email-outline text-gray-400 text-lg" />
                      <ImMobile className="text-lg text-gray-400" />
                    </div>
                    <input
                      type="tel"
                      className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                      placeholder="0612345678"
                      id="phone"
                      name="phoneNumber"
                      onChange={handleInputChange}
                      value={accountFormData.phoneNumber}
                    />
                  </div>
                </label>
              </div>
            </div>
            {false && (
              <div className="flex -mx-3 text-justify">
                <div className="w-full px-3">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    E-mailadres
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-email-outline text-gray-400 text-lg" />
                        <ImMobile className="text-lg text-gray-400" />
                      </div>
                      <input
                        // disabled
                        type="text"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black disabled:opacity-50"
                        placeholder="johndoe@email.com"
                        id="email"
                        name="email"
                        onChange={handleInputChange}
                        value={accountFormData.email}
                      />
                    </div>
                    <span className="text-sm italic">
                      Let op: Uw moet opnieuw inloggen met het nieuwe
                      e-mailadres
                    </span>
                  </label>
                </div>
              </div>
            )}
            <div className="flex -mx-3">
              <div className="w-full px-3 mb-2">
                <button
                  type="submit"
                  className="mt-10 block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold uppercase"
                >
                  Save
                </button>
                <button
                  className="text-sm text-black font-extralight mx-auto mt-4"
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                >
                  Annuleer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeAccountModal;
