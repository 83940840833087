import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Navbar from "../components/Navbar/Navbar";
import NumberPad from "../components/NumberPad/NumPad";
import RedeemPointsInfo from "../components/RedeemPointsInfo/RedeemPointsInfo";
import { useRedeemPointsManuallyMutation } from "../graphql/__generated__/graphql_types";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  totalAmountOfPoints: 0,
};

const RedeemPointsPage = function () {
  const [redeemPointsManually] = useRedeemPointsManuallyMutation();

  const [amountOfPoints, setAmountOfPoints] = useState("");
  const [customerData, setCustomerData] = useState(initialState);

  const history = useHistory();
  const { state } = useHistory().location as any;

  useEffect(() => {
    if (!state?.customerData?.email) history.push("/interface");

    if (state?.customerData) {
      setCustomerData({
        ...state.customerData,
        totalAmountOfPoints: state.totalAmountOfPoints,
      });
    }
  }, [history, state.customerData, state.totalAmountOfPoints]);

  const handleSubmit = () => {
    redeemPointsManually({
      variables: {
        amount: amountOfPoints,
        email: customerData.email,
      },
    })
      .then(({ data }) => {
        toast.success(
          `Er zijn ${data?.redeemPointsManually.amount} punten ingewisseld door ${customerData.firstName}!`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        history.push("/interface", undefined);
      })
      .catch((error) => {
        toast.error(`Er ging iets mis... ${JSON.stringify(error)}`);
      });
  };

  return (
    <div className="h-screen w-screen flex-col items-center justify-center">
      <div className="fixed bottom-0 left-0 right-0 h-screen top-0 bg-home-background-twelve bg-cover bg-no-repeat filter -z-1" />
      <Navbar backIcon />
      <div className="container mx-auto lg:max-w-4xl 2xl:max-w-7xl grid grid-cols-2 lg:gap-32 max-w-3xl gap-20 mt-14 lg:mt-24">
        <div className="bg-white rounded-xl md:h-tablet lg:h-laptop">
          <RedeemPointsInfo
            amountOfPoints={amountOfPoints}
            customerData={customerData}
          />
        </div>
        <div className="flex flex-col">
          <NumberPad
            setDisplayPrice={setAmountOfPoints}
            displayPriceInCents={amountOfPoints}
            handleSubmit={handleSubmit}
            displayInEuro={false}
          />
        </div>
      </div>
    </div>
  );
};

export default RedeemPointsPage;
