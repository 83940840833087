import React, { useEffect, useMemo, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer } from "react-toastify";

import ApolloClient from "./Apollo/ApolloProvider";
import Loading from "./components/Loading/Loading";
import firebaseAuth from "./config/firebase";
import Routes from "./Routes";
import { AppContext } from "./utils/contextLib";

const App = function AppRoutes() {
  const [firebaseUser, loading] = useAuthState(firebaseAuth);
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (firebaseUser) {
      userHasAuthenticated(true);
    }
  }, [firebaseUser]);

  const value = useMemo(
    () => ({ firebaseUser, isAuthenticated, userHasAuthenticated }),
    [isAuthenticated, firebaseUser]
  );

  if (loading) return <Loading />;
  // if (error) return <ErrorPage />;

  return (
    <AppContext.Provider value={value}>
      <ApolloClient>
        <ToastContainer />
        <Routes />
      </ApolloClient>
    </AppContext.Provider>
  );
};

export default App;
