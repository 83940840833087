import React, { useState, useEffect } from "react";

import { User, onAuthStateChanged } from "firebase/auth";
import { Route, Redirect } from "react-router-dom";

import firebaseAuth from "../../config/firebase";
import { useCheckTokenLazyQuery } from "../../graphql/__generated__/graphql_types";
// import ErrorPage from '../Error/ErrorPage';
import Loading from "../Loading/Loading";

const RouteGuard = function ({ children, ...rest }: any) {
  const [
    verifyTokenQuery,
    { data, loading: verifyQueryLoading, error, called },
  ] = useCheckTokenLazyQuery();

  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe: { (): void; (): void };

    const checkState = async () => {
      console.log("Checking state");

      unsubscribe = onAuthStateChanged(firebaseAuth, async (userAuth) => {
        console.log({ userAuth });

        if (userAuth && userAuth.email) {
          console.log("User is logged in");

          const firebaseToken = await userAuth.getIdToken(true);
          verifyTokenQuery({
            variables: { email: userAuth.email },
          });
          setUser(userAuth);
          localStorage.setItem("3wo-token", firebaseToken);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    };

    checkState();
    return function cleanup() {
      unsubscribe();
    };
  }, [verifyTokenQuery]);

  if (loading || (user && !called) || verifyQueryLoading) return <Loading />;
  if (error)
    return <h1 className="text-black"> Error: {JSON.stringify(error)} </h1>;

  return (
    <Route
      {...rest}
      render={({ location }: any) =>
        user && data?.verifyUserToken?.isTokenValid ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default RouteGuard;
