import React from "react";

import { BsArrowRight } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";

import { AdyenStatusNotification } from "../../../graphql/__generated__/graphql_types";
import MemberVisualSummary from "../../MemberVisualSummary/MemberVisualSummary";

const SuccessResponse = function (props: {
  userData: AdyenStatusNotification;
}) {
  const { userData } = props;

  const {
    amountOfLastPayment,
    countOfEcomPayments,
    countOfPOSPayments,
    user,
    totalAmountOfPoints,
    orderId,
    totalCountOfTransactions,
  } = userData;

  return (
    <>
      <div className="bg-green-success h-1/2 rounded-t-xl flex flex-col items-center text-white">
        <div className="-mt-6 flex justify-center  flex-col w-auto rounded-full bg-green-success p-2">
          <FaRegUserCircle size={30} color="white" />
        </div>

        <div className="text-center h-full w-full lg:w-3/4 pt-4 px-8 lg:mt-6">
          <div className="flex items-end justify-center text-black">
            <p className="text-3xl font-semibold">{`${user?.firstName} ${user?.lastName}`}</p>
            <BsArrowRight size={30} />
          </div>
          {orderId && (
            <p className="text-lg font-semibold mt-2">
              {amountOfLastPayment} punten toegevoegd
            </p>
          )}

          <MemberVisualSummary
            countOfEcomPayments={countOfEcomPayments as number}
            countOfPOSPayments={countOfPOSPayments as number}
            totalAmountOfPoints={totalAmountOfPoints as number}
          />
        </div>
      </div>

      <div className="h-1/3 px-8 pt-2 flex items-center">
        <p className="italic font-extralight text-gray-400 text-center">
          Deze klant heeft in totaal{" "}
          <span className="text-green-400 font-bold">
            {totalCountOfTransactions} transacties
          </span>
          , {countOfPOSPayments} in-store, {countOfEcomPayments} online, en{" "}
          <span className="text-green-400 font-bold">
            {totalAmountOfPoints} punten{" "}
          </span>
          in totaal.
        </p>
      </div>
    </>
  );
};

export default SuccessResponse;
