import React, { useEffect } from "react";

import wallet from "../../../assets/wallet.png";
import { useAppContext } from "../../../utils/contextLib";

const Header = function ({
  totalAmountOfPoints,
  name,
}: {
  totalAmountOfPoints: number;
  name: string;
}) {
  const { firebaseUser } = useAppContext();

  const getCardUrl = () => {
    window.location.assign(
      `${process.env.REACT_APP_REMOTE_URL}/api/wallets/pass/download/${firebaseUser?.uid}`
    );
  };

  // Hack for showing image on MacOS devices.
  useEffect(() => {
    if (navigator.vendor === "Apple Computer, Inc.") {
      document
        .querySelector("#bg-image")
        ?.classList.replace("bg-fixed", "bg-scroll");
    }
  }, []);

  return (
    <div id="home">
      <div className="text-center mb-4 -mt-4">
        <p className="text-2xl font-bold">Welkom,</p>
        <p className="text-3xl font-light">{name}</p>
      </div>
      <div
        id="bg-image"
        className="h-80 bg-header-image bg-fixed bg-no-repeat bg-cover bg-right-bottom sm:bg-top flex flex-col justify-center items-center md:h-desktop-header"
      >
        <p className="text-white text-9xl font-light text-center">
          {totalAmountOfPoints}
        </p>
        <p className="text-white text-lg">punten</p>
      </div>
      <div className="text-center py-8 md:flex md:justify-center">
        <div className="md:self-center md:w-1/4">
          <p className="text-2xl">Check sneller jouw saldo</p>
          <p className="text-sm">Voeg jouw spaarkaart toe aan je wallet</p>
        </div>
        <div className="my-3 w-full flex justify-center md:w-1/4 md:h-20">
          <button type="button" onClick={getCardUrl}>
            <img src={wallet} alt="wallet" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
