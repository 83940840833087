import React from "react";

import { Link as ReactScrollLink } from "react-scroll";

const TextSection = function () {
  return (
    <div id="how">
      <div className="bg-white p-8 text-center md:flex md:justify-center">
        <div className="md:w-2/3">
          <p className="text-2xl my-5 font-bold">Hoe spaar je?</p>
          <p className="text-gray-400">
            Je kunt op verschillende manieren sparen. De beste en snelste manier
            is om dit volledig automatisch te doen met jouw bankpas of Apple
            Pay. Wij slaan een unieke code op van jouw bankpas. Die code is niet
            te traceren naar jouw bankaccount of andere priv&#233; data. Wanneer
            jij je registreert, koppelen wij jouw account aan deze code, zodat
            wij jou automatisch punten toekennen.
          </p>

          <ReactScrollLink className="text-white" to="faq" offset={-50}>
            <button
              type="button"
              className="bg-black px-8 py-3 text-white my-8 rounded-lg"
            >
              Meer informatie
            </button>
          </ReactScrollLink>
        </div>
      </div>
    </div>
  );
};

export default TextSection;
