import React from "react";

import { FaRegUserCircle } from "react-icons/fa";

import Ylogo from "../../../assets/Ylogo.png";

const InitialState = function (props: { loading: boolean }) {
  const { loading } = props;

  return (
    <>
      <div className="bg-gray-initial h-1/2 rounded-t-xl flex flex-col items-center">
        <div className="-mt-6 flex justify-center text-gray-500 flex-col">
          <div className="flex justify-center w-auto">
            <div className="bg-gray-initial rounded-full p-2">
              <FaRegUserCircle size={35} />
            </div>
          </div>
          <p className="text-xl text-gray-600 font-semibold">Information</p>
        </div>

        <div className="h-full p-1 px-10 flex items-center flex-col justify-around">
          <h1 className="text-xl italic text-white">
            Start een betaling*, zoek-opdracht of klantherkenning via de pin
          </h1>
          <p className="text-xs font-light italic">
            *Start betaling door op het kassa icoon te klikken
          </p>
        </div>
      </div>

      <div className="px-6 py-2 flex-1">
        <div className="flex h-full flex-col">
          {!loading && (
            <div className="p-5">
              <p className="text-gray-500 text-center text-xl mb-6">
                Mogelijkheden na betaling
              </p>
              <div className="text-gray-600">
                <div className="flex mb-3">
                  <div className="bg-green-success rounded-full h-6 w-6 mr-2" />
                  <span>Member en herkend</span>
                </div>
                <div className="flex mb-3">
                  <div className="bg-orange-unregistered rounded-full h-6 w-6 mr-2" />
                  <span>Geen member, terugkerend</span>
                </div>
                <div className="flex mb-3">
                  <div className="bg-red-unknown rounded-full h-6 w-6 mr-2" />
                  <span>Niet herkend</span>
                </div>
              </div>
            </div>
          )}
          {loading && (
            <div className="flex-col flex items-center h-full justify-center">
              <p className="text-gray-600 italic text-center">
                De betaling is gestart. Wacht tot de klant de betaling heeft
                afgerond.
              </p>
              <div className="animate-spin h-10 w-10 mt-10 flex justify-center">
                <img src={Ylogo} alt="logo" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InitialState;
