import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import { HiOutlineMail } from "react-icons/hi";
import { ImMobile } from "react-icons/im";
import { MdLockOutline, MdPersonOutline } from "react-icons/md";
import { Link, Redirect, useParams } from "react-router-dom";

import logo from "../assets/3wo-logo-transparent.png";
import { InlineLoading } from "../components/Loading/Loading";
import firebaseAuth from "../config/firebase";
import {
  useFindUniqueCardQuery,
  useRegisterUserMutation,
} from "../graphql/__generated__/graphql_types";
import { useAppContext } from "../utils/contextLib";

type ParamTypes = {
  card: string;
};

const RegisterPage = function () {
  // "Getting cardAlias first if exists"
  const { card } = useParams<ParamTypes>();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    customerKey: "",
    termsAgreed: false,
  });
  const [registerError, setRegisterError] = useState<string[] | string>([]);

  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  const { data, loading: queryLoading } = useFindUniqueCardQuery({
    skip: !card,
    variables: {
      card: card!,
    },
  });
  const [registerUser, { loading: isRegisterLoading }] =
    useRegisterUserMutation();

  useEffect(() => {
    if (data?.findUniqueCard) {
      setFormData((form) => ({
        ...form,
        customerKey: data.findUniqueCard!.cardAlias as string,
        phoneNumber: data.findUniqueCard!.phoneNumber as string,
      }));
    }
  }, [data]);

  if (queryLoading)
    return <h1 className="text-center text-black">Loading....</h1>;

  const loginAndRedirect = async () => {
    await signInWithEmailAndPassword(
      firebaseAuth,
      formData.emailAddress,
      formData.password
    ).then(() => {
      localStorage.removeItem("emailForSignIn");
      userHasAuthenticated(true);
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError([]);
    const { target } = event;
    const { name } = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      customerKey,
      emailAddress,
      firstName,
      lastName,
      password,
      phoneNumber,
      termsAgreed,
    } = formData;

    localStorage.setItem("emailForSignIn", emailAddress);

    await registerUser({
      variables: {
        emailAddress,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber,
        termsAgreed,
        password,
        customerKey,
      },
    })
      .then(async () => {
        // TODO: Redirect after succes.
        // localStorage.removeItem("cardHash");
        loginAndRedirect();
      })
      .catch((error) => {
        let message;
        if (error?.graphQLErrors[0]?.extensions?.response?.message?.length) {
          [message] = error.graphQLErrors[0].extensions.response.message;
        } else if (error?.graphQLErrors[0]?.extensions?.code === "P2002") {
          message = "Dit telefoonnummer is al in gebruik.";
        } else {
          message = "Er is iets fout gegaan";
        }

        setRegisterError(message);
      });
  };

  if (isAuthenticated) return <Redirect to="/member" />;

  return (
    <div className="bg-black min-w-screen min-h-screen flex flex-col items-center md:justify-center p-5">
      <div className="md:hidden flex justify-start z-0 h-20 md:h-full mb-8 mt-2">
        <img src={logo} alt="" width={100} />
      </div>
      <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden max-w-5xl max-h-full">
        <div className="md:flex w-full">
          <div className="hidden md:block w-1/2 bg-white py-10 px-10">
            <div className="flex h-full justify-center items-center">
              <img src={logo} alt="" width={300} />
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-1/2 py-10 px-6 md:px-10"
          >
            <div className="text-center mb-5 md:mb-10">
              <h1 className="font-bold text-3xl text-gray-900 uppercase">
                Registreer
              </h1>
              <p>Vul jouw gegevens in om te registreren</p>
            </div>
            <div>
              <div className="flex -mx-3">
                <div className="w-1/2 px-3">
                  <label
                    htmlFor="first-name"
                    className="text-xs font-semibold px-1"
                    aria-controls="first-name"
                  >
                    Voornaam
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <MdPersonOutline className="text-gray-400 text-lg" />
                      </div>
                      <input
                        type="text"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="John"
                        id="first-name"
                        name="firstName"
                        onChange={handleInputChange}
                        value={formData.firstName}
                      />
                    </div>
                  </label>
                </div>

                <div className="w-1/2 px-3">
                  <label
                    htmlFor="last-name"
                    className="text-xs font-semibold px-1"
                  >
                    Achternaam
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <MdPersonOutline className="text-gray-400 text-lg" />
                      </div>
                      <input
                        type="text"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="Smith"
                        id="last-name"
                        name="lastName"
                        onChange={handleInputChange}
                        value={formData.lastName}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3">
                  <label htmlFor="phone" className="text-xs font-semibold px-1">
                    Mobiel nummer
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-email-outline text-gray-400 text-lg" />
                        <ImMobile className="text-lg text-gray-400" />
                      </div>
                      <input
                        type="tel"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="0612345678"
                        id="phone"
                        name="phoneNumber"
                        onChange={handleInputChange}
                        value={formData.phoneNumber}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3">
                  <label htmlFor="email" className="text-xs font-semibold px-1">
                    E-mailadres
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <HiOutlineMail className="text-lg text-gray-400" />
                      </div>
                      <input
                        type="email"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="johnsmith@example.com"
                        name="emailAddress"
                        onChange={handleInputChange}
                        value={formData.emailAddress}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3">
                  <label
                    htmlFor="password"
                    className="text-xs font-semibold px-1"
                  >
                    Wachtwoord
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <MdLockOutline className="text-gray-400 text-lg" />
                      </div>
                      <input
                        type="password"
                        className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500 text-black"
                        placeholder="************"
                        name="password"
                        onChange={handleInputChange}
                        value={formData.password}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-6">
                  <label className="px-1" htmlFor="termsAgreeCheckbox">
                    <input
                      className="rounded mb-1"
                      type="checkbox"
                      name="termsAgreed"
                      id="termsAgreeCheckbox"
                      onChange={handleInputChange}
                      checked={formData.termsAgreed}
                    />
                    <span className="ml-2">Ik ga akkoord met de terms</span>
                  </label>
                </div>
              </div>
              {Array.isArray(registerError) && registerError?.length ? (
                registerError.map((e, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="text-red-600 font-semibold">
                    {e}
                  </div>
                ))
              ) : (
                <div className="text-red-600 font-semibold mb-2">
                  {registerError}
                </div>
              )}
              <div className="flex -mx-3">
                <div className="w-full px-3 mb-2">
                  <button
                    type="submit"
                    className="block w-full max-w-xs mx-auto bg-blue-800 hover:bg-blue-700 focus:bg-blue-700 text-white rounded-lg px-3 py-3 font-semibold uppercase"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isRegisterLoading && <InlineLoading />}
                    REGISTREER
                  </button>
                  <Link
                    className="text-sm text-black font-extralight flex justify-center mt-4"
                    to="/login"
                  >
                    Of klik hier om in te loggen
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
