import React from "react";

import loyyologo from "../../../assets/loyyologo.png";

const Footer = function () {
  return (
    <div className="flex w-full py-3 h-32 justify-center bg-black text-white font-bold">
      <a href="https://www.loyyo.nl" target="_blank" rel="noopener noreferrer">
        <div className="flex py-2 items-center">
          <p>Powered by</p>
          <div className="w-26 ml-2 flex flex-col justify-center">
            <img alt="loyyo logo" src={loyyologo} width="120px" />
          </div>
        </div>
      </a>
    </div>
  );
};

export default Footer;
