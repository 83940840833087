import React, { useEffect, useState } from "react";

import { slide as Menu, State } from "react-burger-menu";
import { Link } from "react-router-dom";
import { Link as ReactScrollLink } from "react-scroll";

import logo from "../../../assets/3wo-logo-black-bg.png";
import { useCheckTokenQuery } from "../../../graphql/__generated__/graphql_types";
import useLogoutFirebase from "../../../hooks/LogoutHook";
import { useAppContext } from "../../../utils/contextLib";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "24px",
    right: "36px",
    top: "22px",
    outline: "2px solid transparent",
    display: "flex",
  },
  bmBurgerBars: {
    background: "#000000",
    // height: "8%",
    // width: "100%",
    // left: "auto",
  },
  bmBurgerBarsHover: {
    background: "#FFFFFF",
    opacity: "1",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: "0",
  },
  bmMenu: {
    background: "#0c0c0c",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
    display: "flex",
    flexDirection: "column",
    lineHeight: "2.5rem",
  },
  bmItem: {
    cursor: "pointer",
    margin: "4px",
  },

  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: "0",
    left: "0",
  },
};

const MembersNavbar = function () {
  const { firebaseUser } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [logOutFromFirebase] = useLogoutFirebase();
  const [isAdmin, setIsAdmin] = useState(false);

  const handleStateChange = (state: State) => {
    setIsOpen(state.isOpen);
  };

  const logOut = () => {
    logOutFromFirebase();
  };

  useCheckTokenQuery({
    variables: { email: firebaseUser?.email as string },
    onCompleted: ({ verifyUserToken }) =>
      setIsAdmin(verifyUserToken?.Role === "ADMIN"),
  });

  const changeMenuBackground = () => {
    if (window.innerWidth < 768) {
      // Phone width
      if (window.scrollY > 540) {
        document.querySelector("header")?.classList.add("bg-white");
      } else {
        document.querySelector("header")?.classList.remove("bg-white");
      }
    } else if (window.scrollY > 740) {
      document.querySelector("header")?.classList.add("bg-white");
    } else {
      document.querySelector("header")?.classList.remove("bg-white");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeMenuBackground, true);

    return window.removeEventListener("scroll", changeMenuBackground);
  });

  return (
    <header className="flex h-20 w-screen  justify-between p-5 sticky top-0">
      <div className="h-10">
        <img src={logo} alt="logo" width="100px" />
      </div>
      <Menu
        styles={styles}
        // width={280}
        disableAutoFocus
        right
        isOpen={isOpen}
        onStateChange={(state) => handleStateChange(state)}
      >
        <ReactScrollLink
          className="text-white"
          to="home"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          Home
        </ReactScrollLink>
        <ReactScrollLink
          className="text-white"
          to="activity"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          Activiteit
        </ReactScrollLink>
        <ReactScrollLink
          className="text-white"
          to="how"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          Hoe spaar je?
        </ReactScrollLink>
        <ReactScrollLink
          className="text-white"
          to="stores"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          Winkels
        </ReactScrollLink>
        <ReactScrollLink
          className="text-white"
          to="account"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          Account
        </ReactScrollLink>
        <ReactScrollLink
          className="text-white"
          to="faq"
          onClick={() => setIsOpen(false)}
          offset={-100}
        >
          FAQ
        </ReactScrollLink>
        <button
          type="button"
          className="text-white outline-none focus:outline-none text-left"
          onClick={logOut}
        >
          Uitloggen
        </button>

        {isAdmin && (
          <Link
            to="/interface"
            className="bg-red-koekemannetje text-center absolute bottom-16 px-10 py-3 text-black"
          >
            Admin Interface
          </Link>
        )}
      </Menu>
    </header>
  );
};

export default MembersNavbar;
