export const devCloud = {
  apiKey: "AIzaSyA0UZtguznRu3DLULEtfrQ1pCZMwd-j5D0",
  authDomain: "loyyo-piggy-3wo-cloud-dev.firebaseapp.com",
  projectId: "loyyo-piggy-3wo-cloud-dev",
  storageBucket: "loyyo-piggy-3wo-cloud-dev.appspot.com",
  messagingSenderId: "829347628123",
  appId: "1:829347628123:web:8423c71e7a8e1c7f64ab7e",
};
export const prodCloud = {
  apiKey: "AIzaSyA6f4BxFlB4hY5hssguMMyQ_IkC1I6D3Ho",
  authDomain: "loyyo-piggy-3wo-production.firebaseapp.com",
  projectId: "loyyo-piggy-3wo-production",
  storageBucket: "loyyo-piggy-3wo-production.appspot.com",
  messagingSenderId: "369908087978",
  appId: "1:369908087978:web:cc8662edbef77ced8a64d3",
};

export const localDev = {
  apiKey: "AIzaSyBk1tRE7yp0_JZG8ENLvFjMF8DxqBoI8jI",
  authDomain: "loyyo-piggy-3wo-local-dev.firebaseapp.com",
  projectId: "loyyo-piggy-3wo-local-dev",
  storageBucket: "loyyo-piggy-3wo-local-dev.appspot.com",
  messagingSenderId: "109444863562",
  appId: "1:109444863562:web:d8e399b440716c2c2bf8b3",
};

export const testEnv = {
  apiKey: "AIzaSyBPLUW2C095j36qz-hs7IVJ5txkawtaFrA",
  authDomain: "loyyo-piggy-3wo-cloud-test.firebaseapp.com",
  projectId: "loyyo-piggy-3wo-cloud-test",
  storageBucket: "loyyo-piggy-3wo-cloud-test.appspot.com",
  messagingSenderId: "469853224884",
  appId: "1:469853224884:web:cf96a85a3a5bf3da9d7ce1",
};
