import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const Faq = function () {
  return (
    <div className="bg-black py-20 justify-center flex text-white" id="faq">
      <div className="w-96 md:w-2/3">
        <p className="text-2xl font-bold text-center">Veelgestelde vragen</p>
        <div className="my-10">
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Hoe kan ik punten sparen?
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Width and paddings have to be specified to avoid unwanted 
            'jumping width' behaviour when we open and close accordion  */}
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Je kunt punten sparen bij iedere aankoop. Elke euro staat
                  gelijk aan 1 punt. Het is gekoppeld aan jouw betaalmethode en
                  als je member bent, worden de punten iedere keer dat je die
                  betaalmethode gebruikt automatisch toegevoegd.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Waar kan ik mijn puntensaldo terugvinden?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Je vind jouw puntensaldo in je account. Iedere keer dat je een
                  aankoop doet, sturen we je een email met je nieuwe punten en
                  je totale puntensaldo.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Wat zijn de beloningen voor mijn punten?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  We hebben speciale beloningen voor onze members. Kijk in onze
                  winkels om achter de aanbieding van de dag te komen.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Hoe kan ik mijn persoonlijke informatie bijwerken?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Je kunt je persoonlijke informatie altijd bijwerken in “Jouw
                  Account”. Klik op “Profiel bijwerken” en dan opslaan.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Kan ik nog een andere betaalmethode toevoegen aan mijn
                  account? Zo ja, hoeveel kan ik er toevoegen?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Natuurlijk, Je kunt zoveel betaalmethodes toevoegen als je
                  wilt. Laat de kassamedewerker weten dat je een member bent en
                  we koppelen de nieuwe betaalmethode aan je account. Er wordt
                  gevraagd om het telefoonnummer dat bij je account hoort.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Hoe kan ik mijn account verwijderen?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  We vinden het jammer dat je geen member meer wilt zijn. In
                  “Mijn Account” kun je de link vinden met “Verwijder Account”.
                  We hopen je in de toekomst weer terug te zien.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Hoe gaan jullie om met privacy en mijn bank informatie?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Wij of onze partners hebben geen toegang tot gevoelige bank
                  informatie van onze members. We slaan je bankkaart gegevens
                  als een token op in ons systeem en alle persoonlijke
                  informatie wordt beheerd volgens de GDPR voorschriften.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Ik heb een aankoop gedaan en de punten kan ik niet terugvinden
                  in mijn totaal of de geschiedenis. Wat moet ik doen om de
                  punten alsnog toe te voegen?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="w-96 p-4 md:w-auto md:p-auto">
                <p>
                  Graag zien we je in een van onze winkels, zodat we kunnen
                  kijken waar het is misgegaan en zodat we het kunnen
                  corrigeren.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
